<template>
  <div>
    <section>
      <b-row>
        <b-col>
          <h3 class="pb-2">{{$t('My Pipeline Sorting Order')}}</h3>

          <draggable v-model="pipelineList" v-bind="dragOptions" @start="drag = true" @end="drag = false" draggable=".draggable-stage">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <b-card class="pipeline mb-1 cursor-pointer" v-for="(pipeline, k) in pipelineList" :key="k" :class="'draggable-stage'">
                <div>
                  #{{ pipeline.id }}
                  {{ pipeline.name }}

                  <span style="float: right; padding: 0 4px;" v-if="false">{{ pipeline.order_nr }}</span>
                  <span style="float: right; padding: 0 4px;" v-if="false">#{{ pipeline.user_order_nr }}</span>
                </div>
              </b-card>
            </transition-group>
          </draggable>
          <el-button @click="savePipelineOrder">{{$t('Save')}}</el-button>
        </b-col>
        <b-col>

        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import apis from "@configs/apis";
import { fetcher, FetchMethod } from "@/libs/axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import draggable from "vuedraggable";

export default {
  components: {
    AdvanceTable,
    vSelect,
    ToastificationContent,
    draggable
  },
  data() {
    return {
      pipelineList: [],
      isLoading: false,
      drag: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  mounted() {
    this.loadPipelineList();
  },
  methods: {
    async loadPipelineList() {
      try {
        this.isLoading = true;
        const response = await fetcher(apis.crmGetPipeline, FetchMethod.GET);
        this.pipelineList = response.data;

        this.showInfo("Pipelines Loaded");
      } catch (e) {
        this.showError(e.message);
      } finally {
        this.isLoading = false;
      }
    },

    async savePipelineOrder() {
      try {
        this.isLoading = true;
        const response = await fetcher(apis.crmPutMePipelineOrder, FetchMethod.PUT, { ids: this.pipelineList.map(a => a.id) });

        this.showInfo("Pipelines Order Saved");
      } catch (e) {
        this.showError(e.message);
      } finally {
        this.isLoading = false;
      }
    },

    showInfo(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "CheckIcon",
          variant: "success"
        }
      });
    },

    showError(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "XIcon",
          variant: "danger"
        }
      });
    },
  }
};
</script>

<style scoped>
.pipeline .card-body {
  padding: 0.5rem 1rem;
}
</style>
